import axios from 'axios'
import serviceTokens from 'services/tokens'

const makeRequest = ({
  headers = {},
  ...config
}) => axios({
  ...config,
  url: process.env.REACT_APP_API_URL + config.url,
  headers: {
    ...headers,
    Authorization: `Bearer ${serviceTokens.getAccess()}`,
    'Content-Type': headers['Content-Type'] || 'application/json'
  }
}).then(
  ({ data }) => data
)

const get = (url, params = {}) => makeRequest({ method: 'GET', url, params })

const _delete = (url, params = {}) => makeRequest({ method: 'DELETE', url, params })

const put = (url, data = {}) => makeRequest({ method: 'PUT', url, data })

const post = (url, data = {}) => makeRequest({ method: 'POST', url, data })

const upload = (url, file) => axios.put(
  url,
  file,
  { headers: { 'Content-Type': file.type } }
)

const service = {
  get,
  delete: _delete,
  put,
  post,
  upload
}

export default service
