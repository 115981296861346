import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Page,
  ButtonGroup,
  Button,
  TextField,
  InlineError,
  Card,
  DataTable,
  Checkbox,
  TextStyle,
  Pagination,
  Modal
} from '@shopify/polaris'
import DateRangePicker from 'components/DateRangePicker'
import TimePicker from 'components/TimePicker'
import Thumbnail from 'components/Thumbnail'
import Loader from 'components/Loader'
import serviceRetailers from 'services/retailers'
import serviceProducts from 'services/products'
import serviceActivations from 'services/activations'
import helperFormatters from 'helpers/formatters'
import s from './index.module.scss'

const NewActivation = () => {
  const [isFirstLoading, setIsFirstLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [retailerName, setRetailerName] = useState('')
  const [title, setTitle] = useState('')
  const [titleError, setTitleError] = useState('')
  const [dateRange, setDateRange] = useState(
    {
      start: new Date(),
      end: new Date()
    }
  )
  const [startTimeIndex, setStartTimeIndex] = useState(0)
  const [endTimeIndex, setEndTimeIndex] = useState(0)
  const [dateTimeError, setDateTimeError] = useState('')
  const [page, setPage] = useState(0)
  const [products, setProducts] = useState([])
  const [hasNext, setHasNext] = useState(false)
  const [hasPrev, setHasPrev] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [selectedProductsError, setSelectedProductsError] = useState('')
  const [productForModal, setProductForModal] = useState({})
  const [modalVariantsIds, setModalVariantsIds] = useState([])
  const [serverError, setServerError] = useState('')
  const [isScheduling, setIsScheduling] = useState(false)

  const navigate = useNavigate()

  const { retailerId } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      setIsFirstLoading(true)
      await serviceRetailers.my()
      setRetailerName(serviceRetailers.getName(retailerId))
      await serviceProducts.getPage().then(data => {
        setProducts(data.products)
        setHasNext(data.hasNext)
        setHasPrev(data.hasPrev)
      })
      setIsFirstLoading(false)
    }
    fetchData()
  }, [retailerId])

  const handleBack = () => {
    if (isLoading) {
      return
    }
    navigate(`/retailers/my/${retailerId}`)
  }

  const handleChangeTitle = title => {
    setTitle(title)
    setTitleError('')
  }

  const handleChangeDateRange = dateRange => {
    setDateRange(dateRange)
    setDateTimeError('')
  }

  const handleChangeStartTimeIndex = startTimeIndex => {
    setStartTimeIndex(startTimeIndex)
    setDateTimeError('')
  }

  const handleChangeEndTimeIndex = endTimeIndex => {
    setEndTimeIndex(endTimeIndex)
    setDateTimeError('')
  }

  const isReadyToSubmit = !(titleError.length || dateTimeError.length || selectedProductsError.length)

  const handleSchedule = () => {
    if (isLoading || !isReadyToSubmit || isScheduling) {
      return
    }

    let isValid = true

    if (!title.length) {
      setTitleError('Activation title is required')
      isValid = false
    }

    if (dateRange.start.getTime() === dateRange.end.getTime() && startTimeIndex >= endTimeIndex) {
      setDateTimeError('Start date must come before end date')
      isValid = false
    }

    if (selectedProducts.length === 0) {
      setSelectedProductsError('Product activations must have at least 1 product')
      isValid = false
    }

    if (!isValid) {
      return
    }

    setIsScheduling(true)
    serviceActivations.create(retailerId, title, dateRange, startTimeIndex, endTimeIndex, selectedProducts).then(handleBack).catch(setServerError).finally(
      () => setIsScheduling(false)
    )
  }

  const isSelectedAllProducts = () => {
    const productsIds = products.map(product => product.id)
    const selectedProductsIds = selectedProducts.map(selectedProduct => selectedProduct.productId)
    const productsEntry = productsIds.map(productsId => selectedProductsIds.includes(productsId))
    return productsEntry.every(productEntry => productEntry === true)
  }

  const isSelectedProduct = product => selectedProducts.some(selectedProduct => selectedProduct.productId === product.id)

  const isSelectedAllVariants = product => {
    if (!isSelectedProduct(product)) {
      return false
    }
    return selectedProducts.filter(selectedProduct => (selectedProduct.productId === product.id))[0].variantIds.length === product.variants.length
  }

  const handleClickHeadingCheckbox = () => {
    if (isLoading) {
      return
    }
    setSelectedProductsError('')
    if (isSelectedAllProducts()) {
      const productsIds = products.map(product => product.id)
      setSelectedProducts(selectedProducts => selectedProducts.filter(selectedProduct => !productsIds.includes(selectedProduct.productId)))
      return
    }
    const selectedProductsIds = selectedProducts.map(selectedProduct => selectedProduct.productId)
    const unselectedProducts = products.filter(product => !selectedProductsIds.includes(product.id))
    setSelectedProducts(selectedProducts => [
      ...selectedProducts,
      ...unselectedProducts.map(uselectedProduct => ({
        productId: uselectedProduct.id,
        variantIds: uselectedProduct.variants.map(variant => variant.id)
      }))
    ])
  }

  const handleCLickProductCheckbox = product => {
    setSelectedProductsError('')
    if (!isSelectedProduct(product)) {
      setSelectedProducts(selectedProducts => [
        ...selectedProducts,
        {
          productId: product.id,
          variantIds: product.variants.map(variant => variant.id)
        }
      ])
      return
    }
    setSelectedProducts(selectedProducts => selectedProducts.filter(selectedProduct => selectedProduct.productId !== product.id))
  }

  const calculateStock = product => product.variants.map(variant => variant.quantity).reduce((acc, cur) => acc + cur)

  const handleAddAllVariants = product => {
    if (isSelectedAllVariants(product)) {
      return
    }
    setSelectedProductsError('')
    setSelectedProducts(selectedProducts => [
      ...selectedProducts.filter(selectedProduct => selectedProduct.productId !== product.id),
      {
        productId: product.id,
        variantIds: product.variants.map(variant => variant.id)
      }
    ])
  }

  const getNextPage = () => {
    if (!hasNext) {
      return
    }
    setIsLoading(true)
    serviceProducts.getPage(page + 1).then(data => {
      setProducts(data.products)
      setHasNext(data.hasNext)
      setHasPrev(data.hasPrev)
      setPage(page => page + 1)
    }).finally(
      () => setIsLoading(false)
    )
  }

  const getPrevPage = () => {
    if (!hasPrev) {
      return
    }
    setIsLoading(true)
    serviceProducts.getPage(page - 1).then(data => {
      setProducts(data.products)
      setHasNext(data.hasNext)
      setHasPrev(data.hasPrev)
      setPage(page => page - 1)
    }).finally(
      () => setIsLoading(false)
    )
  }

  const handleOpenModal = product => {
    setProductForModal(product)
    setModalVariantsIds((selectedProducts.filter(selectedProduct => selectedProduct.productId === product.id)[0] || { variantIds: [] }).variantIds)
  }

  const handleSubmitModal = () => {
    setSelectedProductsError('')
    if (modalVariantsIds.length === 0) {
      setSelectedProducts(selectedProducts => selectedProducts.filter(selectedProduct => selectedProduct.productId !== productForModal.id))
      handleCloseModal()
      return
    }
    if (isSelectedProduct(productForModal)) {
      setSelectedProducts(selectedProducts => selectedProducts.map(selectedProduct => {
        if (selectedProduct.productId !== productForModal.id) {
          return selectedProduct
        }
        return {
          ...selectedProduct,
          variantIds: modalVariantsIds
        }
      }))
    } else {
      setSelectedProducts(selectedProducts => [
        ...selectedProducts,
        {
          productId: productForModal.id,
          variantIds: modalVariantsIds
        }
      ])
    }
    handleCloseModal()
  }

  const handleCloseModal = () => {
    setProductForModal({})
    setModalVariantsIds([])
  }

  const handleClickModalVariant = id => {
    if (modalVariantsIds.includes(id)) {
      setModalVariantsIds(modalVariantsIds => modalVariantsIds.filter(modalVariantsId => modalVariantsId !== id))
      return
    }
    setModalVariantsIds(modalVariantsIds => [...modalVariantsIds, id])
  }

  if (isFirstLoading) {
    return <Loader />
  }

  return (
    <Page
      title={`New Activation for ${retailerName}`}
      primaryAction={(
        <ButtonGroup>
          <Button
            onClick={handleBack}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            primary
            onClick={handleSchedule}
            loading={isLoading}
            disabled={isLoading || !isReadyToSubmit || isScheduling}
          >
            Schedule
          </Button>
        </ButtonGroup>
      )}
      breadcrumbs={[{ content: 'My activations', onAction: handleBack }]}
    >
      {isScheduling && <Loader />}
      <div className={s['new-activation__title-wrapper']}>
        <TextField
          label='Activation title'
          value={title}
          onChange={handleChangeTitle}
          autoComplete='off'
          error={titleError}
        />
        <DateRangePicker
          error={dateTimeError}
          onChange={handleChangeDateRange}
        />
        <div className={s['new-activation__time-wrapper']}>
          <TimePicker
            labelPrefix='Start'
            onChange={handleChangeStartTimeIndex}
          />
          <TimePicker
            labelPrefix='End'
            onChange={handleChangeEndTimeIndex}
          />
        </div>
      </div>
      {!!selectedProductsError.length && (
        <div className={s['new-activation__title-wrapper']}>
          <InlineError message={selectedProductsError} />
        </div>
      )}
      {!!serverError.length && (
        <div className={s['new-activation__title-wrapper']}>
          <InlineError message={serverError} />
        </div>
      )}
      <Card title='Available products:'>
        <DataTable
          columnContentTypes={['text']}
          headings={
            [
              (
                <div className={`${s['new-activation__row']} ${s['new-activation__row--heading']}`}>
                  <div className={`${s['new-activation__ceil']} ${s['new-activation__ceil--heading']}`}>
                    <div onClick={handleClickHeadingCheckbox}>
                      <Checkbox
                        label=''
                        checked={!isLoading && isSelectedAllProducts()}
                      />
                    </div>
                    {selectedProducts.length} product{selectedProducts.length !== 1 && 's'} added to activation
                  </div>
                </div>
              )
            ]
          }
          rows={
            isLoading
              ? []
              : products.map(product => [
                  <div className={s['new-activation__row']}>
                    <div className={`${s['new-activation__ceil']} ${s['new-activation__ceil--first']}`}>
                      <div
                        className={s['new-activation__checkbox']}
                        onClick={() => handleCLickProductCheckbox(product)}
                      >
                        <Checkbox
                          label=''
                          checked={isSelectedProduct(product)}
                        />
                      </div>
                      <Thumbnail
                        alt={product.title}
                        source={product.imageUrl}
                        size='small'
                      />
                      <div className={s['new-activation__product-title']}>
                        <TextStyle variation='strong'>{product.title}</TextStyle>
                        <TextStyle>{helperFormatters.prettyString(calculateStock(product), ',', 3)} in stock</TextStyle>
                      </div>
                    </div>
                    <div className={`${s['new-activation__ceil']} ${s['new-activation__ceil--second']}`}>
                      {
                        (product.variants || []).length <= 1
                          ? (
                            <>
                              <div></div>
                              {
                                isSelectedAllVariants(product)
                                  ? <p className={s['new-activation__text--all-added']}>Product added</p>
                                  : (
                                    <Button
                                      primary
                                      onClick={() => handleAddAllVariants(product)}
                                    >
                                      Add product
                                    </Button>
                                  )
                              }
                            </>
                          )
                          : (
                            <>
                              <Button
                                onClick={() => handleOpenModal(product)}
                                plain={isSelectedProduct(product) && !isSelectedAllVariants(product)}
                              >
                                {
                                  isSelectedProduct(product) && !isSelectedAllVariants(product)
                                    ? `${selectedProducts.filter(selectedProduct => selectedProduct.productId === product.id)[0].variantIds.length} variants added`
                                    : 'Select variants'
                                }
                              </Button>
                              {
                                isSelectedAllVariants(product)
                                  ? <p className={s['new-activation__text--all-added']}>All variants added</p>
                                  : (
                                    <Button
                                      primary
                                      onClick={() => handleAddAllVariants(product)}
                                    >
                                      Add all variants
                                    </Button>
                                  )
                              }
                            </>
                          )
                      }
                    </div>
                  </div>
                ])
          }
        />
      </Card>
      <div className={s['new-activation__pagination-wrapper']}>
        <Pagination
          hasNext={!isLoading && hasNext}
          hasPrevious={!isLoading && hasPrev}
          onNext={getNextPage}
          onPrevious={getPrevPage}
        />
      </div>
      <Modal
        open={!!productForModal.id}
        onClose={handleCloseModal}
        title={`${modalVariantsIds.length} variant${modalVariantsIds.length !== 1 ? 's' : ''} selected`}
        primaryAction={{
          content: 'Add',
          onAction: handleSubmitModal
        }}
        secondaryActions={[
          {
            content: 'Cancel',
            onAction: handleCloseModal
          },
        ]}
      >
        <Modal.Section>
          <div className={s['new-activation__modal-title']}>
            <TextStyle variation='strong'>{productForModal.title}</TextStyle>
          </div>
          {
            (productForModal.variants || []).map(variant => (
              <div
                className={s['new-activation__checkbox']}
                key={productForModal.id + variant.id}
              >
                <Checkbox
                  label={variant.labels.join(' ')}
                  checked={modalVariantsIds.includes(variant.id)}
                  onChange={() => handleClickModalVariant(variant.id)}
                />
              </div>
            ))
          }
        </Modal.Section>
      </Modal>
    </Page>
  )
}

export default NewActivation
