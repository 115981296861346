import modelTokens from 'models/tokens'
import helperLocation from 'helpers/location'

const accessLocalStorageKey = `access:${helperLocation.extractShopName()}`

const saveAccess = access => {
  modelTokens.access = access
  try {
    localStorage.setItem(accessLocalStorageKey, access)
  } catch {

  }
}

const getAccess = () => modelTokens.access

const getTokenPayload = () => {
  try {
    return JSON.parse(atob((getAccess()).split('.')[1]))
  } catch {
    return {
      exp: 0,
      email_verified: false
    }
  }
}

const isValidAccess = () => {
  try {
    return getTokenPayload().exp * 1000 > Date.now()
  } catch (e) {
    return false
  }
}

const loadAccess = () => {
  try {
    saveAccess(localStorage.getItem(accessLocalStorageKey))
  } catch {

  }
}

const isEmailVerified = () => getTokenPayload().email_verified

const service = {
  saveAccess,
  getAccess,
  isValidAccess,
  loadAccess,
  isEmailVerified
}

export default service
