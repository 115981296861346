const extractShopName = () => window.location.ancestorOrigins.length === 1
  ? window.location.ancestorOrigins[0].endsWith('.myshopify.com') && /.*\..*\..*/.test(window.location.ancestorOrigins[0])
    ? window.location.ancestorOrigins[0].split('.')[0].slice(8)
    : ''
  : ''

const helper = {
  extractShopName
}

export default helper
