import { useState } from 'react'
import {
  Card,
  DataTable,
  Heading,
  Badge,
  TextStyle,
  ButtonGroup,
  Button,
  Modal,
  TextContainer
} from '@shopify/polaris'
import Thumbnail from 'components/Thumbnail'
import helperFormatters from 'helpers/formatters'
import s from './index.module.scss'

const ActivationsTable = ({
  title = '',
  activations = [],
  onDelete = async () => {}
}) => {
  const [activationToRemove, setActivationToRemove] = useState({})
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isRemovingActivation, setIsRemovingActivation] = useState(false)

  const badgeColors = {
    live: 'success',
    scheduled: 'info',
    completed: ''
  }

  const handleOpenModal = activation => {
    setActivationToRemove(activation)
    setIsOpenModal(true)
  }

  const handleRemoveActivation = async () => {
    setIsRemovingActivation(true)
    await onDelete(activationToRemove.scheduleId)
    setIsRemovingActivation(false)
    handleCloseModal()
  }

  const handleCloseModal = () => {
    setActivationToRemove({})
    setIsOpenModal(false)
  }

  return (
    <>
      <Card title={title}>
        <DataTable
          columnContentTypes={['text']}
          headings={['']}
          rows={
            activations.map(activation => [
              (
                <div className={s['activations-table__row']}>
                  <div className={`${s['activations-table__ceil']} ${s['activations-table__ceil--first']}`}>
                    <div className={s['activations-table__title-wrapper']}>
                      <Heading>{activation.title}</Heading>
                      <Badge status={badgeColors[activation.status]}>
                        {helperFormatters.capitalize(activation.status)}
                      </Badge>
                    </div>
                    <TextStyle>
                      {helperFormatters.formatDate(activation.startDate)}-{helperFormatters.formatDate(activation.endDate)}
                    </TextStyle>
                  </div>
                  <div className={`${s['activations-table__ceil']} ${s['activations-table__ceil--second']}`}>
                    {
                      activation.products.length > 5
                        ? (
                          <>
                            {
                              activation.products.filter((_, index) => index < 4).map(
                                (product, index) => (
                                  <Thumbnail
                                    alt={product.title}
                                    source={product.imageUrl}
                                    key={`${product.title} ${product.imageUrl} ${index}`}
                                  />
                                )
                              )
                            }
                            <TextStyle>{activation.products.length - 4} more</TextStyle>
                          </>
                        )
                        : (
                          <>
                            {
                              activation.products.map(
                                (product, index) => (
                                  <Thumbnail
                                    alt={product.title}
                                    source={product.imageUrl}
                                    key={`${product.title} ${product.imageUrl} ${index}`}
                                  />
                                )
                              )
                            }
                          </>
                        )
                    }
                  </div>
                  <div className={`${s['activations-table__ceil']} ${s['activations-table__ceil--third']}`}>
                    <ButtonGroup>
                      <Button
                        destructive
                        outline
                        onClick={() => handleOpenModal(activation)}
                      >
                        Remove
                      </Button>
                    </ButtonGroup>
                  </div>
                </div>
              )
            ])
          }
          verticalAlign='middle'
        />
      </Card>
      <Modal
        title=''
        titleHidden
        open={isOpenModal}
        primaryAction={
          {
            content: 'Remove',
            onAction: handleRemoveActivation,
            destructive: true,
            loading: isRemovingActivation
          }
        }
        secondaryActions={
          [
            {
              content: 'Cancel',
              onAction: handleCloseModal,
              disabled: isRemovingActivation
            }
          ]
        }
        onClose={handleCloseModal}
      >
        <Modal.Section>
          <TextContainer>
            <p>Are you sure you want to remove <TextStyle variation='strong'>{activationToRemove.title}</TextStyle> from your activations?</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </>
  )
}

export default ActivationsTable
