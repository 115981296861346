import { useState, useEffect } from 'react'
import { AppProvider } from '@shopify/polaris'
import enTranslations from '@shopify/polaris/locales/en.json'
import { Routes, Route, Navigate } from 'react-router-dom'
import Loader from 'components/Loader'
import GuestOutlet from 'components/outlets/GuestOutlet'
import PrivateOutlet from 'components/outlets/PrivateOutlet'
import MyRetailers from 'pages/retailers/my'
import Retailers from 'pages/retailers'
import MyRetailerDetails from 'pages/retailers/my/details'
import NewActivation from 'pages/retailers/my/details/activations/new'
import StartupGuide from 'pages/startup-guide'
import Onboarding from 'pages/onboarding'
import GetStarted from 'pages/get-started'
import AccountSettings from 'pages/account-settings'
import VerifyEmail from 'pages/verify-email'
import serviceTokens from 'services/tokens'
import '@shopify/polaris/build/esm/styles.css'

const App = () => {
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    serviceTokens.loadAccess()
    setIsLoading(false)
  }, [])

  if (isLoading) {
    return <Loader />
  }

  return (
    <AppProvider i18n={enTranslations}>
      <Routes>
        <Route path='*' element={<Navigate to='/' />} />
        <Route path='/login' element={<Navigate to='/' />} />
        <Route path='/dashboard' element={<Navigate to='/retailers/my' />} />
        <Route path='/*' element={<GuestOutlet />}>
          <Route path='' element={<GetStarted />} />
        </Route>
        <Route path='/*' element={<PrivateOutlet />}>
          <Route path='retailers/my' element={<MyRetailers />} />
          <Route path='retailers' element={<Retailers />} />
          <Route path='retailers/my/:retailerId' element={<MyRetailerDetails />} />
          <Route path='retailers/my/:retailerId/activations/new' element={<NewActivation />} />
          <Route path='startup-guide' element={<StartupGuide />} />
          <Route path='onboarding' element={<Onboarding />} />
          <Route path='account-settings' element={<AccountSettings />} />
          <Route path='verify-email' element={<VerifyEmail />} />
        </Route>
      </Routes>
    </AppProvider>
  )
}

export default App
