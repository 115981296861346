import serviceApi from 'services/api'
import serviceTokens from './tokens'
import serviceAuth from './auth'

const get = () => serviceApi.get('brand/v1/brands')

const update = data => serviceApi.put('brand/v1/brands', data)

const uploadLogo = async file => {
  const uploadFileData = await serviceApi.post(
    'content/v1/contents/uploadContent',
    { mediaType: file.type, filename: file.name }
  )
  await serviceApi.upload(uploadFileData.url, file)
  await update({ logoUrl: uploadFileData.contentFileId })
}

const disconnectShopify = () => update({ shopifyShopName: '', removeShopify: true })

const connectShopify = shopifyShopName => update({ shopifyShopName })

const submitEmailVerification = async () => {
  serviceTokens.saveAccess('')
  await serviceAuth.login()
}

const resendVerificationEmail = () => serviceApi.post('brand/v1/brands/verification-email')

const service = {
  get,
  update,
  uploadLogo,
  disconnectShopify,
  connectShopify,
  submitEmailVerification,
  resendVerificationEmail
}

export default service
