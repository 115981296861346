import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Modal } from '@shopify/polaris'
import serviceUser from 'services/user'
import serviceAuth from 'services/auth'

const DisconnectModal = ({
  isOpen = false,
  onClose = () => {}
}) => {
  const [isDisconnecting, setIsDisconnecting] = useState(false)

  const navigate = useNavigate()

  const handleDisconnect = () => {
    if (isDisconnecting) {
      return
    }
    setIsDisconnecting(true)
    serviceUser.disconnectShopify().then(
      () => {
        serviceAuth.logout()
        navigate('/')
      }
    ).catch(
      () => setIsDisconnecting(false)
    )
  }

  const handleClose = () => {
    if (isDisconnecting) {
      return
    }
    onClose()
  }

  return (
    <Modal
      open={isOpen}
      title='Are you sure you want to disconnect?'
      primaryAction={{ content: 'Disconnect', onAction: handleDisconnect, loading: isDisconnecting, destructive: true }}
      secondaryActions={[{ content: 'Cancel', onAction: handleClose, disabled: isDisconnecting }]}
      onClose={handleClose}
    >
      <Modal.Section>
        <p>All of your Shopify product data will be removed from DISTLL</p>
        <p>This will impact all of the activations in your DISTLL account.</p>
      </Modal.Section>
    </Modal>
  )
}

export default DisconnectModal
