import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Page, Card, DataTable, TextStyle, Button } from '@shopify/polaris'
import Thumbnail from 'components/Thumbnail'
import Loader from 'components/Loader'
import serviceRetailers from 'services/retailers'
import s from './index.module.scss'

const Retailers = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [retailers, setRetailers] = useState([])
  const [myRetailers, setMyRetailers] = useState([])
  const [addingToMyRetailersTypes, setAddingToMyRetailersTypes] = useState([])

  const navigate = useNavigate()

  const handleDone = () => {
    if (addingToMyRetailersTypes.length !== 0) {
      return
    }
    navigate('/retailers/my')
  }

  const handleAddToMyRetailers = retailerType => {
    if (addingToMyRetailersTypes.some(addingToMyRetailersType => addingToMyRetailersType === retailerType)) {
      return
    }
    setAddingToMyRetailersTypes(addingToMyRetailersTypes => [...addingToMyRetailersTypes, retailerType])
    serviceRetailers.addToMy(retailerType).then(
      () => setMyRetailers(myRetailers => [
        ...myRetailers,
        retailers.filter(retailer => retailer.type === retailerType)[0]
      ])
    ).finally(
      () => setAddingToMyRetailersTypes(addingToMyRetailersTypes => addingToMyRetailersTypes.filter(addingToMyRetailersType => addingToMyRetailersType !== retailerType))
    )
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      await serviceRetailers.all().then(({ results }) => setRetailers(results))
      await serviceRetailers.myFromAll().then(({ results }) => setMyRetailers(results))
      setIsLoading(false)
    }
    fetchData()
  }, [])

  if (isLoading) {
    return <Loader />
  }

  return (
    <Page
      title='Add retailers'
      primaryAction={
        {
          content: 'Done',
          onAction: handleDone,
          loading: addingToMyRetailersTypes.length !== 0
        }
      }
      breadcrumbs={
        [
          {
            content: 'My retailers',
            onAction: handleDone
          }
        ]
      }
    >
      <Card>
        <DataTable
          columnContentTypes={
            [
              'text'
            ]
          }
          headings={
            [
              'Add Retailers to start creating activations for them.'
            ]
          }
          rows={
            retailers.map(retailer => [
              <div className={s['retailers__row']}>
                <div className={`${s['retailers__ceil']} ${s['retailers__ceil--first']}`}>
                  <Thumbnail
                    alt={retailer.name}
                    source={retailer.imageUrl}
                    size='small'
                  />
                  <TextStyle variation='strong'>{retailer.name}</TextStyle>
                </div>
                <div className={`${s['retailers__ceil']} ${s['retailers__ceil--second']}`}>
                  {
                    myRetailers.some(myRetailer => myRetailer.type === retailer.type)
                      ? (
                        <Button
                          primary
                          loading={addingToMyRetailersTypes.some(addingToMyRetailersType => addingToMyRetailersType === retailer.type)}
                          onClick={() => handleAddToMyRetailers(retailer.type)}
                          disabled
                        >
                          Added to my retailers
                        </Button>
                      )
                      : (
                        <Button
                          primary
                          loading={addingToMyRetailersTypes.some(addingToMyRetailersType => addingToMyRetailersType === retailer.type)}
                          onClick={() => handleAddToMyRetailers(retailer.type)}
                        >
                          Add to my retailers
                        </Button>
                      )
                  }
                </div>
              </div>
            ])
          }
          verticalAlign='middle'
        />
      </Card>
    </Page>
  )
}

export default Retailers
