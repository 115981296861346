import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Page,
  Card,
  EmptyState,
  DataTable,
  Tooltip,
  TextStyle,
  Button,
  Modal,
  TextContainer
} from '@shopify/polaris'
import Thumbnail from 'components/Thumbnail'
import Loader from 'components/Loader'
import imageEmptyState from 'assets/images/empty-state.png'
import serviceRetailers from 'services/retailers'
import helperFormatters from 'helpers/formatters'
import s from './index.module.scss'

const MyRetailers = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [activeRetailers, setActiveRetailers] = useState([])
  const [inactiveRetailers, setInactiveRetailers] = useState([])
  const [retailerToRemove, setRetailerToRemove] = useState({})
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isRemovingRetailer, setIsRemovingRetailer] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)
    serviceRetailers.my().then(
      ({ active, inactive }) => {
        setActiveRetailers(active)
        setInactiveRetailers(inactive)
      }
    ).finally(
      () => setIsLoading(false)
    )
  }, [])

  const handleAddRetailer = () => navigate('/retailers')

  const handleOpenModal = retailer => {
    setRetailerToRemove(retailer)
    setIsOpenModal(true)
  }

  const handleCloseModal = () => {
    if (isRemovingRetailer) {
      return
    }
    setRetailerToRemove({})
    setIsOpenModal(false)
  }

  const handleOpenRetailer = ({ target }, retailerId) => {
    if ([...target.classList].join(' ').includes('Polaris-Button')) {
      return
    }
    navigate(`/retailers/my/${retailerId}`)
  }

  const handleRemoveRetailer = () => {
    setIsRemovingRetailer(true)
    serviceRetailers.removeMy(retailerToRemove.syndicatePartnerType).then(
      () => {
        const filterCallback = retailers => retailers.filter(
          retailer => retailer.syndicatePartnerType !== retailerToRemove.syndicatePartnerType
        )
        setActiveRetailers(filterCallback)
        setInactiveRetailers(filterCallback)
      }
    ).finally(
      () => {
        handleCloseModal()
        setIsRemovingRetailer(false)
      }
    )
  }

  if (isLoading) {
    return <Loader />
  }

  if (!activeRetailers.length && !inactiveRetailers.length) {
    return (
      <Page>
        <div className={s['my-retailers__empty-state-wrapper']}>
          <Card sectioned>
            <EmptyState
              heading='Your retailers will show here'
              action={{ content: 'Add retailer', onAction: handleAddRetailer }}
              image={imageEmptyState}
            >
              <p>This is where you’ll select retailers and send your products to their stores.</p>
            </EmptyState>
          </Card>
        </div>
      </Page>
    )
  }

  return (
    <Page
      title='My retailers'
      primaryAction={{ content: 'Add retailer', onAction: handleAddRetailer }}
    >
      {!!activeRetailers.length && (
        <Card title='ACTIVE'>
          <DataTable
            columnContentTypes={
              [
                'text'
              ]
            }
            headings={
              [
                'These Retailers currently have live, scheduled, or drafted activations.'
              ]
            }
            rows={
              activeRetailers.map(activeRetailer => [
                (
                  <Tooltip content={`View this retailer's activations`}>
                    <div
                      className={`${s['my-retailers__row']} ${s['my-retailers__row--active']}`}
                      onClick={e => handleOpenRetailer(e, activeRetailer.syndicatePartnerType)}
                    >
                      <div className={s['my-retailers__ceil']}>
                        <Thumbnail
                          alt={activeRetailer.displayName}
                          source={activeRetailer.imageUrl}
                          size='small'
                        />
                      </div>
                      <div className={`${s['my-retailers__ceil']} ${s['my-retailers__ceil--active-second']}`}>
                        <TextStyle variation='strong'>{activeRetailer.displayName}</TextStyle>
                        <TextStyle>{helperFormatters.formatDateTime(activeRetailer.lastUpdated)}</TextStyle>
                      </div>
                      <div className={`${s['my-retailers__ceil']} ${s['my-retailers__ceil--active-third']}`}>
                        <TextStyle variation='strong'>{activeRetailer.liveSchedulesCount}</TextStyle>
                        <TextStyle> live activation{activeRetailer.liveSchedulesCount !== 1 && 's'}</TextStyle>
                      </div>
                      <div className={`${s['my-retailers__ceil']} ${s['my-retailers__ceil--active-fourth']}`}>
                        <Button
                          destructive
                          outline
                          onClick={() => handleOpenModal(activeRetailer)}
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  </Tooltip>
                )
              ])
            }
            verticalAlign='middle'
          />
        </Card>
      )}
      {!!inactiveRetailers.length && (
        <Card title='INACTIVE'>
          <DataTable
            columnContentTypes={
              [
                'text'
              ]
            }
            headings={
              [
                'These Retailers currently have none of your products.'
              ]
            }
            rows={
              inactiveRetailers.map(inactiveRetailer => [
                (
                  <Tooltip content='Go to retailer'>
                    <div
                      className={`${s['my-retailers__row']} ${s['my-retailers__row--inactive']}`}
                      onClick={e => handleOpenRetailer(e, inactiveRetailer.syndicatePartnerType)}
                    >
                      <div className={s['my-retailers__ceil']}>
                        <Thumbnail
                          alt={inactiveRetailer.displayName}
                          source={inactiveRetailer.imageUrl}
                          size='small'
                        />
                      </div>
                      <div className={`${s['my-retailers__ceil']} ${s['my-retailers__ceil--inactive-second']}`}>
                        <TextStyle variation='strong'>{inactiveRetailer.displayName}</TextStyle>
                        <TextStyle>{helperFormatters.formatDateTime(inactiveRetailer.lastUpdated)}</TextStyle>
                      </div>
                      <div className={`${s['my-retailers__ceil']} ${s['my-retailers__ceil--inactive-third']}`}>
                        <Button
                          destructive
                          outline
                          onClick={() => handleOpenModal(inactiveRetailer)}
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  </Tooltip>
                )
              ])
            }
            verticalAlign='middle'
          />
        </Card>
      )}
      <Modal
        title=''
        titleHidden
        open={isOpenModal}
        primaryAction={
          {
            content: 'Remove',
            onAction: handleRemoveRetailer,
            destructive: true,
            loading: isRemovingRetailer
          }
        }
        secondaryActions={
          [
            {
              content: 'Cancel',
              onAction: handleCloseModal,
              disabled: isRemovingRetailer
            }
          ]
        }
        onClose={handleCloseModal}
      >
        <Modal.Section>
          <TextContainer>
            <p>Are you sure you want to remove <TextStyle variation='strong'>{retailerToRemove.displayName}</TextStyle> from your retailers?</p>
          </TextContainer>
        </Modal.Section>
      </Modal>
    </Page>
  )
}

export default MyRetailers
