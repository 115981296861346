const activationsFilterOptions = [
  { label: 'All', value: '' },
  { label: 'Live', value: 'live' },
  { label: 'Scheduled', value: 'scheduled' },
  { label: 'Completed', value: 'completed' }
]

const activationTimes = new Array(24).fill(0).map((_, index) => ({
  label: `${index < 10 ? '0' + index : index}:00`,
  value: `${index < 10 ? '0' + index : index}:00`
}))

const helper = {
  activationsFilterOptions,
  activationTimes
}

export default helper
