import { useState, useCallback, useEffect } from 'react'
import { Outlet, Navigate, useNavigate, useLocation } from 'react-router-dom'
import { Page, Icon, Popover, ActionList } from '@shopify/polaris'
import { SettingsMajor } from '@shopify/polaris-icons'
import Loader from 'components/Loader'
import Footer from 'components/Footer'
import DisconnectModal from 'components/DisconnectModal'
import serviceTokens from 'services/tokens'
import serviceUser from 'services/user'
import serviceAuth from 'services/auth'
import helperLocation from 'helpers/location'
import s from './index.module.scss'

const LAST_ONBOARD_STEP = 2

const PrivateOutlet = () => {
  const [isOpenPopover, setIsOpenPopover] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [onboardStep, setOnboardStep] = useState(0)
  const [isOnboardedShopify, setIsOnboardedShopify] = useState(null)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    setIsOpenModal(false)
    setIsLoading(true)
    serviceUser.get().then(
      user => {
        setOnboardStep(user.onboardStep)
        setIsOnboardedShopify(user.isOnboardedShopify)
        if (!user.shopifyConnected || user.shopifyShopName !== helperLocation.extractShopName()) {
          serviceAuth.logout()
        }
      }
    ).finally(
      () => setIsLoading(false)
    )
  }, [location, onboardStep])

  const toggleIsOpenPopover = useCallback(
    () => setIsOpenPopover(isOpenPopover => !isOpenPopover),
    []
  )

  const handleOpenModal = () => setIsOpenModal(true)

  const handleClickLink = link => {
    document.body.click()
    navigate(link)
  }

  if (isLoading) {
    return <Loader />
  }

  if (!serviceTokens.isValidAccess()) {
    return <Navigate to='/login' />
  }

  if (!serviceTokens.isEmailVerified() && location.pathname !== '/verify-email') {
    return <Navigate to='/verify-email' />
  }

  if (!serviceTokens.isEmailVerified()) {
    return (
      <div className={s['private-outlet']}>
        <div>
          <Page
            primaryAction={(
              <Popover
                active={isOpenPopover}
                activator={(
                  <div
                    className={s['private-outlet__icon-wrapper']}
                    onClick={toggleIsOpenPopover}
                  >
                    <Icon source={SettingsMajor} color='base' />
                  </div>
                )}
                onClose={toggleIsOpenPopover}
              >
                <ActionList
                  items={
                    [
                      {
                        content: 'Disconnect',
                        onAction: handleOpenModal
                      }
                    ]
                  }
                />
              </Popover>
            )}
          >
          </Page>
          <Outlet />
          <DisconnectModal
            isOpen={isOpenModal}
            onClose={() => setIsOpenModal(false)}
          />
        </div>
      </div>
    )
  }

  if (onboardStep <= LAST_ONBOARD_STEP && location.pathname !== '/onboarding') {
    return <Navigate to='/onboarding' />
  }

  if (onboardStep <= LAST_ONBOARD_STEP) {
    return <Outlet />
  }

  if (!isOnboardedShopify && location.pathname !== '/startup-guide') {
    return <Navigate to='/startup-guide' />
  }

  if (!isOnboardedShopify) {
    return <Outlet />
  }

  return (
    <div className={s['private-outlet']}>
      <div>
        <Page
          primaryAction={(
            <Popover
              active={isOpenPopover}
              activator={(
                <div
                  className={s['private-outlet__icon-wrapper']}
                  onClick={toggleIsOpenPopover}
                >
                  <Icon source={SettingsMajor} color='base' />
                </div>
              )}
              onClose={toggleIsOpenPopover}
            >
              <ActionList
                items={
                  [
                    {
                      content: 'Account settings',
                      onAction: () => handleClickLink('/account-settings')
                    },
                    {
                      content: 'Startup guide',
                      onAction: () => handleClickLink('/startup-guide')
                    },
                    {
                      content: 'Disconnect',
                      onAction: handleOpenModal
                    }
                  ]
                }
              />
            </Popover>
          )}
        >
        </Page>
        <Outlet />
        <DisconnectModal
          isOpen={isOpenModal}
          onClose={() => setIsOpenModal(false)}
        />
      </div>
      <Footer />
    </div>
  )
}

export default PrivateOutlet
