import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link, Modal } from '@shopify/polaris'
import imageSneakersMobile from 'assets/images/sneakers-mobile.png'
import imageSneakersDesktop from 'assets/images/sneakers-desktop.png'
import serviceAuth from 'services/auth'
import serviceUser from 'services/user'
import helperLocation from 'helpers/location'
import s from './index.module.scss'
import Loader from 'components/Loader'

const GetStarted = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isActiveAccountConflictModal, setIsActiveAccountConflictModal] = useState(serviceAuth.getIsAccountConflict())
  const [isActiveStoreConflictModal, setIsActiveStoreConflictModal] = useState(serviceAuth.getIsStoreConflict())

  const navigate = useNavigate()

  const handleOpenAccountConflictModal = () => {
    setIsActiveAccountConflictModal(true)
    serviceAuth.setIsAccountConflict(true)
  }

  const handleCloseAccountConflictModal = () => {
    setIsActiveAccountConflictModal(false)
    serviceAuth.setIsAccountConflict(false)
  }

  const handleOpenStoreConflictModal = () => {
    setIsActiveStoreConflictModal(true)
    serviceAuth.setIsStoreConflict(true)
  }

  const handleCloseStoreConflictModal = () => {
    setIsActiveStoreConflictModal(false)
    serviceAuth.setIsStoreConflict(false)
  }

  const handleClickConnect = async () => {
    setIsLoading(true)
    handleCloseAccountConflictModal()
    handleCloseStoreConflictModal()
    const shopifyShopName = helperLocation.extractShopName()
    if (!shopifyShopName) {
      setIsLoading(false)
      return
    }
    await serviceAuth.login()
    const user = await serviceUser.get()
    if (user.shopifyConnected && user.shopifyShopName !== shopifyShopName) {
      handleOpenAccountConflictModal()
      serviceAuth.logout()
      setIsLoading(false)
      return
    }
    if (!user.shopifyConnected) {
      const updatedUser = await serviceUser.connectShopify(shopifyShopName)
      if (!updatedUser.shopifyConnected || updatedUser.shopifyShopName !== shopifyShopName) {
        handleOpenStoreConflictModal()
        serviceAuth.logout()
        setIsLoading(false)
        return
      }
    }
    setIsLoading(false)
    navigate('/dashboard')
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <div className={s['get-started']}>
      <div className={s['get-started__content']}>
        <h1 className={s['get-started__title']}>Amplify Your Story.</h1>
        <h1 className={s['get-started__title']}>Amplify Your Revenue.</h1>
        <div className={s['get-started__line']}></div>
        <h2 className={s['get-started__subtitle']}>Your Next Generation Commerce Solution.</h2>
        <div className={s['get-started__button']} onClick={handleClickConnect}>Connect</div>
        <div className={s['get-started__text']}>
          By clicking CONNECT, you agree to accept DISTLL's <Link url='https://www.distll.com/terms/terms-of-service' external>terms and conditions</Link> and <Link url='https://www.distll.com/terms/privacy-policy' external>privacy policy</Link><br />
          You'll pay a commission of 10% on sales made through DISTLL. 
        </div>
      </div>
      <img
        src={imageSneakersMobile}
        className={`${s['get-started__image']} ${s['get-started__image--mobile']}`}
        alt='Sneakers'
      />
      <img
        src={imageSneakersDesktop}
        className={`${s['get-started__image']} ${s['get-started__image--desktop']}`}
        alt='Sneakers'
      />
      <Modal
        title=''
        titleHidden
        open={isActiveAccountConflictModal}
        secondaryActions={[
          {
            content: 'Close',
            onAction: handleCloseAccountConflictModal,
          }
        ]}
        onClose={handleCloseAccountConflictModal}
      >
        <p className={s['get-started__modal-text']}>This account is connected to another Shopify store</p>
      </Modal>
      <Modal
        title=''
        titleHidden
        open={isActiveStoreConflictModal}
        secondaryActions={[
          {
            content: 'Close',
            onAction: handleCloseStoreConflictModal,
          }
        ]}
        onClose={handleCloseStoreConflictModal}
      >
        <p className={s['get-started__modal-text']}>This Shopify store is connected to another account</p>
      </Modal>
    </div>
  )
}

export default GetStarted
