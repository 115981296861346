import { useState, useCallback } from 'react'
import { TextField, Popover, ActionList } from '@shopify/polaris'
import helperConstants from 'helpers/constants'
import s from './index.module.scss'

const TimePicker = ({
  labelPrefix = '',
  onChange = () => {}
}) => {
  const [isOpenPopover, setIsOpenPopover] = useState(false)
  const [selectedTimeIndex, setSelectedTimeIndex] = useState(0)

  const toggleIsOpenPopover = useCallback(
    () => setIsOpenPopover(isOpenPopover => !isOpenPopover),
    []
  )

  const handleChange = index => {
    setSelectedTimeIndex(index)
    onChange(index)
    toggleIsOpenPopover()
  }

  return (
    <div className={s['time-picker']}>
      <Popover
        active={isOpenPopover}
        activator={(
          <div onClick={toggleIsOpenPopover}>
            <TextField
              value={helperConstants.activationTimes[selectedTimeIndex].label}
              label={`${labelPrefix} time`}
              autoComplete='off'
            />
          </div>
        )}
        onClose={toggleIsOpenPopover}
      >
        <ActionList
          items={
            helperConstants.activationTimes.map((activationTime, index) => ({
              content: activationTime.label,
              onAction: () => handleChange(index)
            }))
          }
        />
      </Popover>
    </div>
  )
}

export default TimePicker
