import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Page, Card, EmptyState } from '@shopify/polaris'
import imageStartupGuide1Step from 'assets/images/startup-guide-1-step.png'
import imageStartupGuide2Step from 'assets/images/startup-guide-2-step.png'
import imageStartupGuide3Step from 'assets/images/startup-guide-3-step.png'
import serviceUser from 'services/user'
import s from './index.module.scss'

const StartupGuide = () => {
  const [step, setStep] = useState(0)

  const navigate = useNavigate()

  useEffect(
    () => serviceUser.update({ isOnboardedShopify: true }),
    []
  )

  const steps = [
    {
      text: 'Select and add retailers.',
      image: imageStartupGuide1Step
    },
    {
      text: 'In My Retailers select a retailer to create an activation. Activations are how you send your products to selected retailers.',
      image: imageStartupGuide2Step
    },
    {
      text: 'Send targeted product activations to your retailers.',
      image: imageStartupGuide3Step
    }
  ]

  const action = step === steps.length - 1
    ? { content: 'Get started', onAction: () => navigate('/') }
    : { content: 'Next step', onAction: () => setStep(step => step + 1) }

  const secondaryAction = step !== 0
    ? { secondaryAction: { content: 'Previous step', onAction: () => setStep(step => step - 1) } }
    : {}

  return (
    <Page>
      <div className={s['startup-guide__empty-state-wrapper']}>
        <Card sectioned>
          <EmptyState
            heading={`Step ${step + 1}`}
            action={action}
            {...secondaryAction}
            image={steps[step].image}
          >
            <p>{steps[step].text}</p>
            <div className={s['startup-guide__marks']}>
              {
                steps.map((_, index) => (
                  <span
                    className={
                      s['startup-guide__mark']
                      + (step === index ? ` ${s['startup-guide__mark--active']}` : '')
                    }
                    key={index}
                  >
                  </span>
                ))
              }
            </div>
          </EmptyState>
        </Card>
      </div>
    </Page>
  )
}

export default StartupGuide
