import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Page,
  ButtonGroup,
  Button,
  Layout,
  TextStyle,
  AccountConnection,
  Card,
  TextField,
  Banner
} from '@shopify/polaris'
import Loader from 'components/Loader'
import Thumbnail from 'components/Thumbnail'
import LogoUploader from 'components/LogoUploader'
import DisconnectModal from 'components/DisconnectModal'
import serviceUser from 'services/user'
import serviceProducts from 'services/products'
import helperLocation from 'helpers/location'
import s from './index.module.scss'

const AccountSettings = () => {
  const [isFirstLoadng, setIsFirstLoading] = useState(true)
  const [isLoadng, setIsLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [companyWebsite, setCompanyWebsite] = useState('')
  const [contactName, setContactName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [phone, setPhone] = useState('')
  const [ecommerce, setEcommerce] = useState('')
  const [shopifyShopName, setShopifyShopName] = useState('')
  const [logoUrl, setLogoUrl] = useState('')
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [productsExternalIds, setProductsExternalIds] = useState([])
  const [isShowProductsBanner, setIsShowProductsBanner] = useState(serviceProducts.getIsShowProductsBanner())

  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      await serviceUser.get().then(data => {
        setEmail(data.email)
        setCompanyWebsite(data.domainName)
        setContactName(data.fullName)
        setCompanyName(data.company)
        setPhone(data.phone)
        setEcommerce(data.ecommerce)
        setShopifyShopName(data.shopifyShopName)
        setLogoUrl(data.logoUrl)
      })
      await serviceProducts.getAllMyProductsExternalIds().then(data => setProductsExternalIds(data.ids))
      setIsFirstLoading(false)
    }
    fetchData()
  }, [])

  const handleClickCancel = () => {
    if (isLoadng) {
      return
    }
    navigate('/')
  }

  const handleClickSave = () => {
    if (isLoadng) {
      return
    }
    setIsLoading(true)
    serviceUser.update({
      fullName: contactName,
      productInterests: [],
      ecommerce,
      phone,
      shopifyShopName
    }).finally(() => setIsLoading(false))
  }

  const handleOpenModal = () => setIsOpenModal(true)

  const handleDissmisProductsBanner = () => {
    setIsShowProductsBanner(false)
    serviceProducts.setIsShowProductsBanner(false)
  }

  if (isFirstLoadng) {
    return <Loader />
  }

  return (
    <Page
      title='Account settings'
      primaryAction={(
        <ButtonGroup>
          <Button
            disabled={isLoadng}
            onClick={handleClickCancel}
          >
            Cancel
          </Button>
          <Button
            primary
            loading={isLoadng}
            onClick={handleClickSave}
          >
            Save
          </Button>
        </ButtonGroup>
      )}
      breadcrumbs={
        [
          {
            content: 'Cancel',
            onAction: handleClickCancel
          }
        ]
      }
    >
      <Layout>
        <div className={s['account-settings__preview']}>
          <Thumbnail
            alt={contactName}
            source={logoUrl}
          />
          <TextStyle variation='strong'>{companyName}</TextStyle>
        </div>
        <Layout.AnnotatedSection
          title='Profile image'
          description='Update your profile image'
        >
          <div className={s['account-settings__profile-image-wrapper']}>
            <div className={s['account-settings__logo-uploader-wrapper']}>
              <LogoUploader
                disabled={isLoadng}
                showPreview={false}
                onLoadEnd={setLogoUrl}
              />
            </div>
          </div>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title='Distll account'
          description='DISTLL charges a 10% commission at the time of sale. Commissions are collected automatically through our platform.'
        >
          <AccountConnection
            accountName={companyName}
            details={email}
            connected
            action={{ content: 'Disconnect', onAction: handleOpenModal }}
            avatarUrl={logoUrl || 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII='}
          />
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title='Company and contact'
        >
          <Card>
            <div className={s['account-setting__input']}>
              <TextField
                label='Contact Name'
                disabled={isLoadng}
                value={contactName}
                onChange={setContactName}
              />
            </div>
            <div className={s['account-setting__input']}>
              <TextField
                label='Contact Email'
                disabled
                value={email}
              />
            </div>
            <div className={s['account-setting__input']}>
              <TextField
                label='Contact Phone'
                disabled={isLoadng}
                value={phone}
                onChange={setPhone}
              />
            </div>
            <div className={s['account-setting__input']}>
              <TextField
                label='Company Name'
                disabled
                value={companyName}
                onChange={setCompanyName}
              />
            </div>
            <div className={s['account-setting__input']}>
              <TextField
                label='Company Website'
                disabled
                value={companyWebsite}
              />
            </div>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title='Publishing'
          description='Products that are being synced to your catalog, or have errors preventing their sync, are shown here.'
        >
          <Card
            title='Product status'
            actions={
              [
                {
                  url: `https://${helperLocation.extractShopName()}.myshopify.com/admin/products/bulk?resource_name=Product&edit=variants.sku,variants.price,variants.compare_at_price&return_to=/admin/products&show=&metafield_titles=&metafield_options=&selectedView=all&order=title asc&ids=${productsExternalIds.join(',')}`,
                  content: 'Manage availability',
                  external: true
                }
              ]
            }
            sectioned
          >
            {
              isShowProductsBanner
                ? (
                  <>
                    <Banner
                      status='info'
                      onDismiss={handleDissmisProductsBanner}
                    >
                      Products publishing to Distll can take 5 minutes to update. Once your products are successfully published your products will be visible on Distll.
                    </Banner>
                    <br />
                  </>
                )
                : <></>
            }
            <p><b>{productsExternalIds.length}</b> product{productsExternalIds.length === 1 ? '' : 's'} are available to Distll</p>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
      <DisconnectModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
      />
    </Page>
  )
}

export default AccountSettings
