const model = {
  retailerId: '',
  filter: '',
  prevEvaluatedKeys: ['draft-0'],
  pageSize: 10,
  q: '',
  hasNext: true,
  hasPrev: false,
  total: 0,
  isDeletePrevRequest: false
}

export default model
