import { Card, EmptyState, DataTable, TextStyle } from '@shopify/polaris'
import Thumbnail from 'components/Thumbnail'
import imageEmptyState from 'assets/images/empty-state.png'
import s from './index.module.scss'

const LiveProductsTable = ({
  liveProducts = []
}) => {
  if (!liveProducts.length) {
    return (
      <Card sectioned>
        <EmptyState image={imageEmptyState}>
          <p>You have not live products at this time.</p>
        </EmptyState>
      </Card>
    )
  }

  return (
    <Card>
      <DataTable
        columnContentTypes={[
          'text',
          'numeric',
          'text'
        ]}
        headings={[
          'Title',
          'Stock',
          'Price'
        ]}
        rows={
          liveProducts.map(liveProduct => [
            (
              <div className={`${s['live-products-table__ceil']} ${s['live-products-table__ceil--first']}`}>
                <Thumbnail
                  alt={liveProduct.title}
                  source={liveProduct.imageUrl}
                />
                <div>
                  <TextStyle variation='strong'>{liveProduct.title}</TextStyle>
                  {
                    liveProduct.variantTitle.length
                      ? (
                        <>
                          <br />
                          <TextStyle variation='subdued'>Variant: {liveProduct.variantTitle}</TextStyle>
                        </>
                      )
                      : <></>
                  }
                </div>
              </div>
            ),
            liveProduct.stock,
            `$${liveProduct.price}`
          ])
        }
        verticalAlign='middle'
      />
    </Card>
  )
}

export default LiveProductsTable
