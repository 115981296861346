import { Auth0Client } from '@auth0/auth0-spa-js'
import serviceTokens from 'services/tokens'
import helperLocation from 'helpers/location'

const isAccountConflictLocalStorageKey = `isAccountConflict:${helperLocation.extractShopName()}`
const isStoreConflictLocalStorageKey = `isStoreConflict:${helperLocation.extractShopName()}`

const Auth0 = class {
  constructor() {
    this.auth0 = new Auth0Client({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirect_uri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
      scope: process.env.REACT_APP_AUTH0_SCOPE,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      useCookiesForTransactions: true,
      useRefreshTokens: true
    })
  }

  login = async () => {
    await this.auth0.loginWithPopup()
    await this.auth0.getTokenSilently()
    const idTokenPayload = await this.auth0.getIdTokenClaims()
    return idTokenPayload.__raw
  }

  logout = () => this.auth0.logout({ returnTo: `${process.env.REACT_APP_AUTH0_REDIRECT_URI}login` })
}

const auth0 = new Auth0()

const login = async () => {
  const token = await auth0.login()
  serviceTokens.saveAccess(token)
}

const logout = () => {
  auth0.logout()
  serviceTokens.saveAccess('')
}

const setIsAccountConflict = isAccountConflict => {
  try {
    localStorage.setItem(isAccountConflictLocalStorageKey, isAccountConflict)
  } catch {

  }
}

const getIsAccountConflict = () => {
  try {
    return JSON.parse(localStorage.getItem(isAccountConflictLocalStorageKey))
  } catch {
    return false
  }
}

const setIsStoreConflict = isStoreConflict => {
  try {
    localStorage.setItem(isStoreConflictLocalStorageKey, isStoreConflict)
  } catch {

  }
}

const getIsStoreConflict = () => {
  try {
    return JSON.parse(localStorage.getItem(isStoreConflictLocalStorageKey))
  } catch {
    return false
  }
}

const service = {
  login,
  logout,
  setIsAccountConflict,
  getIsAccountConflict,
  setIsStoreConflict,
  getIsStoreConflict
}

export default service
